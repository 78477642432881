import React, { useState, useEffect } from 'react';
import { Modal, RadioGroup, FormControlLabel, Radio, Button, Box, Autocomplete, Pagination, Typography, TextField } from '@mui/material';
import axios from 'axios';
import { APIURL } from '../../../config';

const LinkCode = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const selectedProcCode = props.selectedProcCodes ? props.procList.find((procCode) => procCode.procCode_id == props.selectedProcCodes[props.taskType.id]) : '';
    const [selectedProvider, setSelectedProvider] = useState(selectedProcCode ? props.procedureData.find((procData) => procData.procCode_id === selectedProcCode?.procCode_id)?.providerIdentifier : null);
    const [selectedPayer, setSelectedPayer] = useState(selectedProcCode ? props.procedureData.find((procData) => procData.procCode_id === selectedProcCode?.procCode_id)?.payerIdentifier : null);
    const [selectedOffice, setSelectedOffice] = useState(selectedProcCode ? props.procedureData.find((procData) => procData.procCode_id === selectedProcCode?.procCode_id)?.officeIdentifier : null);
    const [taskRate, setTaskRate] = useState(selectedProcCode ? props.procedureData.find((procData) => procData.procCode_id === selectedProcCode?.procCode_id)?.taskRate : null);

    useEffect(() => {
        console.log("A" + props.selectedProcCodes);
    }, [props.selectedProcCodes]);

    useEffect(() => {
        setTaskRate(selectedProcCode ? props.procedureData.find((procData) => procData.procCode_id === selectedProcCode?.procCode_id)?.taskRate : null);
        setSelectedOffice(selectedProcCode ? props.procedureData.find((procData) => procData.procCode_id === selectedProcCode?.procCode_id)?.officeIdentifier : null);
        setSelectedPayer(selectedProcCode ? props.procedureData.find((procData) => procData.procCode_id === selectedProcCode?.procCode_id)?.payerIdentifier : null);
        setSelectedProvider(selectedProcCode ? props.procedureData.find((procData) => procData.procCode_id === selectedProcCode?.procCode_id)?.providerIdentifier : null);
    }, [props.open]);

    const handleClose = async () => {
        props.setOpen(false);
        setTaskRate(null);
        setSelectedOffice(null);
        setSelectedPayer(null);
        setSelectedProvider(null);
    };

    const handleLink = async () => {
        await axios.post(APIURL + "/procedureCodes/handleMappingForTaskProcCode", {
            dropdown_id: Object.keys(props.selectedProcCodes).find(key => props.selectedProcCodes[key] == selectedProcCode.procCode_id),
            procedure_code_id: selectedProcCode.procCode_id,
            comp_id: props.comp_id,
            taskRate: taskRate,
            officeIdentifier: selectedOffice,
            payerIdentifier: selectedPayer,
            providerIdentifier: selectedProvider,
        });

        handleClose();
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Calculate the items to display based on currentPage and itemsPerPage
    const paginatedItems = props.procList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div>
            <Modal
                open={props.open} // Use props.open if provided, otherwise use local state
                onClose={handleClose}
                aria-labelledby="link-code-modal"
                aria-describedby="link-code-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        height: '60vh',
                        overflowY: 'auto'
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <Box>
                            <Typography variant="h5" id="link-code-modal" sx={{ mb: 1 }}>
                                Link Code
                            </Typography>
                            <Typography variant="body1" id="link-code-modal-description" sx={{ mb: 2 }}>
                                Procedure Code with Task type - {props.taskType?.title}
                            </Typography>

                            <Autocomplete
                                id="ProcCode-select"
                                value={selectedProcCode}
                                options={props.procList}
                                getOptionLabel={(option) => option.procCode_description}
                                onChange={(event, value) => props.setSelectedProcCodes({ ...props.selectedProcCodes, [props.taskType.id]: value.procCode_id })}
                                renderInput={(params) => <TextField {...params} label="Select Procedure Code" margin="normal" />}
                                fullWidth
                                size="large"
                            />

                            <TextField
                                variant="outlined"
                                label={`Enter Task Rate`}
                                type='number'
                                size='large'
                                value={taskRate}
                                onChange={(e) => setTaskRate(e.target.value)}
                                fullWidth
                            // sx={{ marginTop: 5 }}
                            />

                            <Autocomplete
                                id="Provider-select"
                                value={selectedProvider && { prov_iden_id: selectedProvider, prov_iden: props.providerList.find((prov) => prov.prov_iden_id === selectedProvider)?.prov_iden }}
                                options={props.providerList}
                                getOptionLabel={(option) => option.prov_iden}
                                onChange={(event, value) => setSelectedProvider(value.prov_iden_id)}
                                renderInput={(params) => <TextField {...params} label="Select Provider" margin="normal" />}
                                fullWidth
                                size="large"
                            />

                            <Autocomplete
                                id="Payer-select"
                                value={selectedPayer && { payer_id: selectedPayer, payer_name: props.payerList.find((payer) => payer.payer_id === selectedPayer)?.payer_name }}
                                options={props.payerList}
                                getOptionLabel={(option) => option.payer_name}
                                onChange={(event, value) => setSelectedPayer(value.payer_id)}
                                renderInput={(params) => <TextField {...params} label="Select Payer" margin="normal" />}
                                fullWidth
                                size="large"
                            />

                            <Autocomplete
                                id="Office-select"
                                value={selectedOffice && { oloc_id: selectedOffice, oloc_addr_line1: props.officeList.find((payer) => payer.oloc_id === selectedOffice)?.oloc_addr_line1 }}
                                options={props.officeList}
                                getOptionLabel={(option) => option.oloc_addr_line1}
                                onChange={(event, value) => setSelectedOffice(value.oloc_id)}
                                renderInput={(params) => <TextField {...params} label="Select Ofiice" margin="normal" />}
                                fullWidth
                                size="large"
                            />

                            {/* <RadioGroup
                                aria-label="procedure-code"
                                name="procedure-code"
                                value={props.selectedProcCodes ? props.selectedProcCodes[props.taskType.id] : ''}
                                onChange={(e) => props.setSelectedProcCodes({ ...props.selectedProcCodes, [props.taskType.id]: e.target.value })}
                            >
                                {paginatedItems.map((proc) => (
                                    <FormControlLabel
                                        key={proc.procCode_id}
                                        value={proc.procCode_id}
                                        control={<Radio />}
                                        label={proc.procCode_description}
                                    />
                                ))}
                            </RadioGroup> */}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            {/* <Pagination
                                count={Math.ceil(props.procList.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            /> */}
                            <Button
                                variant="contained"
                                onClick={handleLink}
                                sx={{ mt: 2 }}
                            >
                                Link
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default LinkCode;
